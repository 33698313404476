export class PhoneFormatterValueConverter {
  fromView(stringPhoneNumber: string) {
    if (stringPhoneNumber == null || stringPhoneNumber.length < 10) return;

    stringPhoneNumber.replace("-", "");
    stringPhoneNumber.replace(" ", "");
    stringPhoneNumber.replace("(", "");
    stringPhoneNumber.replace(")", "");

    return parseInt(stringPhoneNumber, 10);
  }

  toView(intPhoneNumber: number) {
    if (intPhoneNumber == null || intPhoneNumber == 0) return "";
    if (intPhoneNumber.toString().length != 10)
      return intPhoneNumber.toString();

    return intPhoneNumber
      .toString()
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
}
