import { bindingMode, bindable } from "aurelia-framework";
import { ISubscription, ISubscriptionType } from "services/interfaces";

export class ActiveSubscriptionElement {
  @bindable({ defaultBindingMode: bindingMode.toView })
  subscription: ISubscription;

  basicSXRaysCount: number;
  teethCleaningsCount: number;
  dentistCheckupsCount: number;
  emergencyVisitsAndAssessmentsCount: number;
  straightTeethAssessmentsCount: number;
  veneersDiscountCount: number;
  virtualConsultsCount: number;
  inOfficeWhiteningsCount: number;
  clearAlignerTherapiesCount: number;
  oralCancerScreeningsCount: number;
  liveBacterialAnalysesCount: number;
  photographicRecordsCount: number;
  digitalSmileDesignsCount: number;
  smileTryInsCount: number;
  swagBagCount: number;

  showingServices: boolean = true;

  attached() {
    this.setInitialCounts(this.subscription.subscriptionType);
    
    if (this.subscription.servicesRendered.length > 0) {
      this.showServicesRendered();
    }
  }

  async showServicesRendered() {
    await this.setInitialCounts(this.subscription.subscriptionType);

    this.subscription.servicesRendered.forEach((service) => {
      this.decrementCount(service.name);
    });
  }

  async setInitialCounts(subscriptionType: ISubscriptionType) {
    this.basicSXRaysCount = subscriptionType.basicXRays;
    this.teethCleaningsCount = subscriptionType.teethCleanings;
    this.dentistCheckupsCount = subscriptionType.dentistCheckups;
    this.emergencyVisitsAndAssessmentsCount =
      subscriptionType.emergencyVisitsAndAssessments;
    this.straightTeethAssessmentsCount =
      subscriptionType.straightTeethAssessments;
    this.virtualConsultsCount = subscriptionType.virtualConsults;
    this.inOfficeWhiteningsCount = subscriptionType.inOfficeWhitenings;
    this.clearAlignerTherapiesCount = subscriptionType.clearAlignerTherapies;
    this.oralCancerScreeningsCount = subscriptionType.oralCancerScreenings;
    this.liveBacterialAnalysesCount = subscriptionType.liveBacterialAnalyses;
    this.photographicRecordsCount = subscriptionType.photographicRecords;
    this.digitalSmileDesignsCount = subscriptionType.digitalSmileDesigns;
    this.smileTryInsCount = subscriptionType.smileTryIns;
    this.swagBagCount = subscriptionType.swagBags;
  }
  async decrementCount(service: string) {
    switch (service) {
      case "Basic X-Ray":
        this.basicSXRaysCount--;
        break;
      case "Teeth Cleaning":
        this.teethCleaningsCount--;
        break;
      case "Dentist Checkup":
        this.dentistCheckupsCount--;
        break;
      case "Emergency Visit & Assessment":
        this.emergencyVisitsAndAssessmentsCount--;
        break;
      case "Straight Teeth Assessment":
        this.straightTeethAssessmentsCount--;
        break;
      case "Virtual Consult":
        this.virtualConsultsCount--;
        break;
      case "In-Office Whitening":
        this.inOfficeWhiteningsCount--;
        break;
      case "Clear Aligner Therapy":
        this.clearAlignerTherapiesCount--;
        break;
      case "Oral Cancer Screening":
        this.oralCancerScreeningsCount--;
        break;
      case "Live Bacterial Analysis":
        this.liveBacterialAnalysesCount--;
        break;
      case "Photographic Record":
        this.photographicRecordsCount--;
        break;
      case "Digital Smile Design":
        this.digitalSmileDesignsCount--;
        break;
      case "Smile Try-In":
        this.smileTryInsCount--;
        break;
      case "Swag Bag":
        this.swagBagCount--;
        break;
    }
  }
}
