import { initialState } from "./services/state";
import { Aurelia } from "aurelia-framework";
import environment from "../config/environment.json";
import { PLATFORM } from "aurelia-pal";
import { AuthenticationService } from "services/authentication-service";

export function configure(aurelia: Aurelia): void {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName("resources/index"));

  aurelia.use.developmentLogging(environment.debug ? "debug" : "warn");

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-store"), { initialState });
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-dialog"));

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName("aurelia-testing"));
  }

  // if (AuthenticationService.isLoggedIn())
  aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName("app")));
  // else
  //   aurelia
  //     .start()
  //     .then(() => aurelia.setRoot(PLATFORM.moduleName("login-register")));
}
