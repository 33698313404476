import { AuthenticationService } from "./services/authentication-service";
import { PLATFORM } from "aurelia-pal";
import { autoinject } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import { Store, connectTo } from "aurelia-store";
import { State } from "services/state";
import {
  setIsAdmin,
  updateActiveSubscriptions,
  updateAllAppointments,
  updateAppointmentRequests,
  updateAppointmentTypes,
  updateAppointments,
  updateClientBirthdays,
  updateCreditCards,
  updatePatient,
  updatePatientAppointmentRequests,
  updatePatients,
  updateServices,
  updateSubscription,
  updateSubscriptionTypes,
  updateTodaysAppointments,
  updateTransactions,
  updateUser,
} from "services/state-actions";
import { IUser } from "services/interfaces";
import { StateManager } from "services/state-manager";

@connectTo<State>()
@autoinject()
export class App {
  router: Router;
  private _store: Store<State>;
  private _authenticationService: AuthenticationService;
  user: IUser;
  isLoading: boolean;
  stateManager: StateManager;

  constructor(
    store: Store<State>,
    authenticationService: AuthenticationService,
    stateManager: StateManager
  ) {
    this._store = store;
    this._authenticationService = authenticationService;
    this.stateManager = stateManager;

    this._store.registerAction("updateUser", updateUser);
    this._store.registerAction(
      "updateAppointmentTypes",
      updateAppointmentTypes
    );
    this._store.registerAction("updateAppointments", updateAppointments);
    this._store.registerAction(
      "updateTodaysAppointments",
      updateTodaysAppointments
    );
    this._store.registerAction("updateAllAppointments", updateAllAppointments);
    this._store.registerAction(
      "updateAppointmentRequests",
      updateAppointmentRequests
    );
    this._store.registerAction(
      "updatePatientAppointmentRequests",
      updatePatientAppointmentRequests
    );
    this._store.registerAction("updateCreditCards", updateCreditCards);
    this._store.registerAction(
      "updateSubscriptionTypes",
      updateSubscriptionTypes
    );
    this._store.registerAction("updateSubscription", updateSubscription);
    this._store.registerAction("updatePatient", updatePatient);
    this._store.registerAction("updatePatients", updatePatients);
    this._store.registerAction(
      "updateActiveSubscriptions",
      updateActiveSubscriptions
    );
    this._store.registerAction("updateServices", updateServices);
    this._store.registerAction("setIsAdmin", setIsAdmin);
    this._store.registerAction("updateClientBirthdays", updateClientBirthdays);
    this._store.registerAction("updateTransactions", updateTransactions);
  }

  async created() {
    // if (AuthenticationService.isLoggedIn()) {
    //   this.isLoading = true;
    //   this.user = await this.stateManager.getUserAsync();
    //   if (this.user.isAdmin) this._authenticationService.isAdministrator = true;
    // } else {
    //   this.stateManager.setUserNullAsync();
    //   this.router.navigateToRoute("");
    // }
    // if (
    //   this._authenticationService.isAuthenticated() ||
    //   this._authenticationService.session != null
    // ) {
    //   this.isLoading == true;
    //   this.user = await this.stateManager.getUserAsync();
    //   if (this.user.isAdmin) this._authenticationService.isAdministrator = true;
    // }
  }

  async attached() {
    if (AuthenticationService.isLoggedIn()) {
      this.isLoading = true;
      this.user = await this.stateManager.getUserAsync();
      if (this.user.isAdmin) this._authenticationService.isAdministrator = true;
    } else {
      this.stateManager.setUserNullAsync();
      this.router.navigateToRoute("home");
    }
  }

  stateChanged(state: State) {
    this.user = state.user;
    if (this.user) {
      this.isLoading = false;
    }
  }

  async configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.title = "Ro Dental Studio";
    config.addPipelineStep("authorize", AuthorizeStep);

    const routes = [
      {
        route: ["", "home"],
        name: "home",
        moduleId: PLATFORM.moduleName("./pages/home"),
        nav: true,
        title: "Home",
        settings: {
          icon: "home",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: null,
        },
      },
      {
        route: "login",
        name: "login",
        moduleId: PLATFORM.moduleName("./pages/login"),
        nav: false,
        title: "Login",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: false,
          adminRoute: null,
        },
      },
      {
        route: "register",
        name: "register",
        moduleId: PLATFORM.moduleName("./pages/register"),
        nav: false,
        title: "Register",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: false,
          adminRoute: null,
        },
      },
      {
        route: "book-now",
        name: "book-now",
        moduleId: PLATFORM.moduleName("./pages/book-now"),
        nav: false,
        title: "Book Now",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: false,
          adminRoute: null,
        },
      },
      {
        route: "password-reset",
        name: "password-reset",
        moduleId: PLATFORM.moduleName("./pages/password-reset"),
        nav: false,
        title: "Password Reset",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: false,
          adminRoute: null,
        },
      },
      {
        route: "account-confirmed",
        name: "account-confirmed",
        moduleId: PLATFORM.moduleName("./pages/account-confirmed"),
        nav: false,
        title: "Email Verified",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: false,
          adminRoute: null,
        },
      },
      {
        route: "today",
        name: "today",
        moduleId: PLATFORM.moduleName("./pages/todays-appointments"),
        nav: true,
        title: "Today",
        settings: {
          icon: "calendar",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "all-appointments",
        name: "all-appointments",
        moduleId: PLATFORM.moduleName("./pages/all-appointments"),
        nav: true,
        title: "Appointments",
        settings: {
          icon: "check",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "appointment-requests",
        name: "appointment-requests",
        moduleId: PLATFORM.moduleName("./pages/appointment-requests"),
        nav: true,
        title: "Appointment Requests",
        settings: {
          icon: "calendar-check",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "members",
        name: "members",
        moduleId: PLATFORM.moduleName("./pages/members"),
        nav: true,
        title: "Members",
        settings: {
          icon: "user-group",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "clients",
        name: "clients",
        moduleId: PLATFORM.moduleName("./pages/clients"),
        nav: true,
        title: "Clients",
        settings: {
          icon: "users",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "patient/:userId",
        name: "patient",
        moduleId: PLATFORM.moduleName("./pages/patient"),
        nav: false,
        title: "Patient",
        settings: {
          icon: "user",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
      {
        route: "dashboard",
        name: "dashboard",
        moduleId: PLATFORM.moduleName("./pages/dashboard"),
        nav: true,
        title: "Dashboard",
        settings: {
          icon: "house",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: false,
        },
      },
      {
        route: "appointments",
        name: "appointments",
        moduleId: PLATFORM.moduleName("./pages/appointments"),
        nav: true,
        title: "Appointments",
        settings: {
          icon: "calendar-days",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: false,
        },
      },
      {
        route: "transactions",
        name: "transactions",
        moduleId: PLATFORM.moduleName("./pages/transactions"),
        nav: true,
        title: "Transactions",
        settings: {
          icon: "receipt",
          isExpanded: false,
          subPages: [],
          showInNavigation: true,
          adminRoute: true,
        },
      },
    ];

    config.map(routes);

    config.fallbackRoute("login");
  }

  logout() {
    this._authenticationService.logout();
  }
}

@autoinject()
class AuthorizeStep {
  private _authenticationService: AuthenticationService;

  constructor(authenticationService: AuthenticationService) {
    this._authenticationService = authenticationService;
  }

  run(navigationInstruction, next) {
    const adminRoutes = [
      "today",
      "all-appointments",
      "appointment-requests",
      "members",
      "patient",
      "clients",
      "transactions",
    ];
    const userRoutes = ["dashboard", "appointments"];

    const globalRoutes = [
      "login",
      "register",
      "book-now",
      "password-reset",
      "home",
      "account-confirmed",
    ];

    //Depending on what the navgiationInstruction is ie is the path allowed and then use the authentication Service to differentiate between the roles
    if (
      this._authenticationService.isAdministrator == true &&
      navigationInstruction
        .getAllInstructions()
        .some(
          (i) =>
            adminRoutes.includes(i.config.name) ||
            globalRoutes.includes(i.config.name)
        )
    ) {
      return next();
    } else if (
      this._authenticationService.isAdministrator == false &&
      navigationInstruction
        .getAllInstructions()
        .some(
          (i) =>
            userRoutes.includes(i.config.name) ||
            globalRoutes.includes(i.config.name)
        )
    ) {
      return next();
    } else {
      return next.cancel();
    }
  }
}
