import numeral from "numeral";

export class CurrencyFormatValueConverter {
  toView(value: number) {
    if (Number.isNaN(value) == true) {
      return numeral(0).format("($0,0.00)");
    }
    return numeral(value).format("($0,0.00)");
  }
}
