import { bindable, bindingMode } from "aurelia-framework";

export class ObjectSearchSelectElement {
  @bindable isEditing: boolean;
  @bindable isEditable: boolean;
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  options: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  selectedOption: any;
  @bindable displayKey: string;
  @bindable displayKeyTwo: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) searchTerm: string;

  dropdownExpanded: boolean = false;

  selectOption(option) {
    if (option == this.selectedOption) return;
    this.selectedOption = option;
    this.dropdownExpanded = false;
  }
  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
  }
}
