import { inject } from "aurelia-dependency-injection";
import {
  bindable,
  autoinject,
  TaskQueue,
  bindingMode,
} from "aurelia-framework";
import * as moment from "moment";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

@inject(TaskQueue)
export class DateElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) date: Date;
  @bindable() isEditing: boolean;
  @bindable() isEditable: boolean;
  @bindable() isDateTime: boolean = false;
  @bindable() hideYear?: boolean = false;

  flatPickerObject: any;

  private _taskQueue: TaskQueue;

  constructor(taskQueue: TaskQueue) {
    this._taskQueue = taskQueue;
  }

  attached() {
    this._taskQueue.queueTask(() => {
      if (this.isEditable) {
        this.createDatePicker();
      }
    });
  }
  createDatePicker() {
    var htmlElement = document.getElementById("date-picker");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      dateFormat: "F d",
      onChange: (date) => {
        this.date = date[0];
      },
    });
  }
}
